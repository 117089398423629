body,
body.mat-typography {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: $base-font-family;
    line-height: 1.4;
    color: $primary-color;
    font-weight: 500;
  }
  h1 {
    font-size: 25px;
  }
  h2 {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
    .margin-btmzero {
      margin-bottom: 0px;
    }
    @media screen and (max-width: $tablet-large-width) {
      font-size: 16px;
    }
  }
  h3 {
    font-size: 16px;
    @media screen and (max-width: $tablet-large-width) {
      font-size: 14px;
    }
  }
  h4 {
    font-size: 14px;
    @media screen and (max-width: $tablet-large-width) {
      font-size: 13px;
    }
  }
  h5 {
    font-size: 12px;
    @media screen and (max-width: $tablet-large-width) {
      font-size: 11px;
    }
  }
  h6 {
    font-size: 10px;
    @media screen and (max-width: $tablet-large-width) {
      font-size: 9px;
    }
  }
  .mat-stepper-vertical,
  .mat-stepper-horizontal {
    font-family: $base-font-family;
  }
}
.mat-toolbar,
.mat-table,
.mat-body p,
.mat-typography {
  font-family: $base-font-family !important;
}
@media screen and (max-width: $tablet-large-width) {
  .mat-body,
  .mat-body-1,
  .mat-typography.mat-body,
  .mat-body-1,
  .mat-typography {
    font-size: 13px !important;
  }
}

.ht-100 {
  height: 100%;
}
.subhead {
  padding-left: 30px;
  position: relative;
  color: $secondary-color;
  &::before {
    background: url(../../assets/images/right-arrow.svg) no-repeat center center;
    width: 6px;
    height: 10px;
    position: absolute;
    left: 10px;
    top: 50%;
    content: "";
    @include transform(translateY(-50%));
  }
}
.primary-flat-button {
  background-color: $dark-blue-color !important;
  color: white !important;
  height: 36px;
  &.acceptBtn {
    color: $green-color;
    background-color: $light-green-color;
  }
  &.warnBtn {
    color: $red-color !important;
    background-color: $light-red-color !important;
  }
}
.primary-stroke-button {
  background-color: $white;
  border: 1px solid $dark-blue-color !important;
  color: $dark-blue-color !important;
  height: 36px;
}
button.mat-button-disabled {
  background-color: $light-grey-color !important;
  color: white;
}
.link-button {
  background-color: transparent;
  color: $light-blue-color !important;
  display: inline-block;
  font-weight: 700;
  font-size: $base-font;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.seprator {
  background-color: $lightest-blue-color;
  display: inline-block;
  width: 1px;
  height: 22px;
  vertical-align: middle;
  margin: 0 12px;
}
.comn-box {
  background: $background-shade;
  border: 0.8px solid $lightest-blue-color;
  @include border-radius(8px);
}
.guru-flex {
  @include flexStyle(flex, center, center);
}
.guru-justify-space-between {
  justify-content: space-between;
}
.new-flag-item {
  background: #fff url(../../assets/images/new.svg) no-repeat right top / 40px;
}
.btn-group {
  display: flex;
}
.apexcharts-text {
  font-family: $base-font-family !important;
  font-size: 14px;
  font-weight: 500;
  fill: #7b7b7b;
}

.increaseValue {
  font-weight: bold;
  font-size: 11px;
  display: flex;
  align-items: center;
  color: $green-color;
  &::before {
    content: "";
    width: 12px;
    height: 13px;
    margin-right: 2px;
    @include mask(contain, left center, no-repeat);
    -webkit-mask-image: url(../../assets/images/increase-icon.svg);
    mask-image: url(../../assets/images/increase-icon.svg);
    background: $green-color;
  }
}
.decreaseValue {
  font-weight: bold;
  font-size: 11px;
  display: flex;
  align-items: center;
  color: $red-color;
  &::before {
    content: "";
    width: 12px;
    height: 13px;
    margin-right: 2px;
    @include mask(contain, left center, no-repeat);
    -webkit-mask-image: url(../../assets/images/decrease-icon.svg);
    mask-image: url(../../assets/images/decrease-icon.svg);
    background: $red-color;
  }
}

.guru-full-grey-bg {
  margin: 0 -65px;
  padding-left: 65px;
  padding-right: 65px;
  background: $background-shade;
  padding-top: 40px;
  padding-bottom: 0px;
}

.redemption-status {
  @include border-radius(5px);
  padding: 5px 10px;
  max-width: 105px;
  @include flexStyle(flex, center, center);
  &::before {
    content: "";
    width: 17px;
    height: 17px;
    margin-right: 8px;
    background-position: center center;
    background-size: 17px 17px;
    background-repeat: no-repeat;
  }
  &.accepted {
    color: #2bb24c;
    background: #ecffd6;
    &::before {
      background-image: url("../../assets/images/accepted-icon.svg");
    }
  }
  &.rejected {
    color: #d83232;
    background: #ffe9e9;
    &::before {
      background-image: url("../../assets/images/rejected-icon.svg");
    }
  }
}
.head-dropdown {
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  h2 {
    margin-bottom: 0px !important;
  }
}

.profile-section {
  display: inline-block;
  margin-bottom: 55px;
  width: 100%;
}

.guru-wrap {
  margin: 0px -15px;
  @media screen and (max-width: $tablet-large-width) {
    margin: 0px -10px;
  }
}
.tb-temp-hide {
  opacity: 0;
  visibility: hidden;
}
.hide {
  display: none;
}

.action-toaster {
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
}

.toaster-msg {
  font-weight: 600;
  min-width: 320px;
  max-width: 360px;
  font-weight: 600;
}
.changes-subhead {
  font-size: $small-font;
  color: $secondary-color;
  font-weight: $medium-weight;
  text-transform: capitalize;
  margin-left: 10px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
