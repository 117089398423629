.summary-box {
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid $lightest-blue-color;
  box-sizing: border-box;
  @include border-radius(5px);
  min-height: 75px;
  margin: 0px 7.5px;
  width: 25%;
  padding: 10px 10px 7px 13px;
  position: relative;
  h2 {
    font-weight: $medium-weight !important;
    color: $primary-color;
    text-transform: unset;
    margin-bottom: 0px;
    small {
      font-size: $small-font;
      color: $secondary-color;
      display: block;
      font-weight: $medium-weight;
    }
  }
  .coupan-val {
    position: absolute;
    right: 7px;
    bottom: 5px;
    .increaseValue,
    .decreaseValue {
      justify-content: flex-end;
      margin-bottom: -5px;
    }
    small {
      color: $dark-grey-color;
      font-size: 10px;
      font-weight: $medium-weight;
    }
  }
  &.increase-col {
    background: $light-green-color;
    border: 1px solid $light-green-color;
    box-shadow: 3px 7px 15px rgba(169, 202, 130, 0.4);
  }
  &.decrease-col {
    background: $light-red-color;
    border: 1px solid $light-red-color;
    box-shadow: 3px 7px 15px rgba(215, 181, 181, 0.4);
  }
}
.summary-row {
  display: flex;
  padding: 16px 0px;
  .summary-left-col {
    width: 50%;
    padding: 0px 13px;
    .summary-box {
      width: 50%;
    }
  }
  .summary-full-col {
    width: 100%;
    padding: 0px 13px;
    .summary-box {
      width: 50%;
    }
  }
  .summary-right-col {
    width: 50%;
    padding: 0px 13px;
    .summary-box {
      width: 50%;
    }
  }
  .summary-col {
    display: flex;
    margin: 0px -7.5px;
  }
}

.summarytab-row {
  display: flex;
}
.summarytab-head {
  .summarytab-col {
    font-weight: 700;
    padding-bottom: 5px;
  }
}
.summarytab-col {
  width: 33.33%;
  font-weight: 500;
  font-size: $base-font;
  color: $primary-color;
  padding-right: 10px;
}
.summarytab-body {
  .summarytab-col {
    padding: 16px 0px;
    border-bottom: 1px solid #d4e3f0;
    display: flex;
    align-items: center;
    padding-right: 10px;
  }
}

.summary-img,
.brand-img,
.vehicle-img,
.guru-img {
  width: 38px;
  height: 38px;
  background: #f1f1f1;
  border: 1px solid #c0d5e8;
  border-radius: 25px;
  margin-right: 10px;
  overflow: hidden;
  margin-left: 1px;
  @include flexStyle(flex, center, center);
  img {
    max-width: 100%;
    max-height: 34px;
    object-fit: cover;
    width: 100%;
  }
}
.guru-img {
  img {
    max-height: 100%;
  }
}
.guru-detail,
.summary-detail {
  flex: 1;
}
