.card-item-inner-box {
  align-items: flex-start;
  width: 100%;
  height: 100%;
  .item-display {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .item-image {
    width: 100%;
    text-align: center;
    margin: 20px 0 15px 0px;
    img {
      max-width: 100%;
      max-height: 170px;
    }
  }

  .item-text {
    display: flex;
    flex-direction: column;
    width: calc(100% - 32px);
    padding: 8px 16px 16px 0px;
  }
  .item-heading {
    text-transform: unset;
    margin-bottom: 0px;
    font-weight: $medium-weight;
    font-size: $large-font;
    line-height: 21px;
    color: $primary-color;
    span {
      display: block;
    }
  }
  .item-size {
    font-weight: $medium-weight;
    font-size: $base-font;
    line-height: 17px;
    color: $secondary-color;
    padding-top: 8px;
    margin-bottom: 0px;
  }
  .item-footer {
    bottom: 6px;
    padding: 0 8px;
    height: 66px;
    font-weight: $medium-weight;
    font-size: $base-font;
    line-height: 17px;
    color: $primary-color;
    background: transparent;
    @include border-radius(5px);
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    .item-footer-text {
      @include flexStyle(flex, center, space-between);
      padding: 5px 8px;
      @include border-radius(5px);
      height: 34px;
    }
  }
}
