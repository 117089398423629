.tb-notification-wrapper {
  .tb-notification-container {
    display: none;
  }
  &.showDropdown {
    .tb-notification-container {
      display: block;
    }
  }
}
