// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "~@angular/material" as mat;
// Plus imports for other components in your app.
@import "~bootstrap/scss/bootstrap";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./_vendor/fonts";
@import "./_vendor/vendors";
@import "./_modules/colors";
@import "./_modules/icons";
@import "./_modules/mixins";
@import "./_modules/variables";
@import "./_partials/common";
@import "./_partials/header";
@import "./_partials/search";
@import "./_partials/utility";
@import "./_partials/forms";
@import "./_partials/table";
@import "./_partials/card";
@import "./_partials/tabs";
@import "./_partials/summary-box";
@import "./_partials/progress-chart";
@import "./_partials/campaign";
@import "./_partials/profile";
@import "./_partials/training";
@import "./_partials/login";
@import "./_partials/role-detail";
@import "./_partials/superadmin";
@import "./_partials/notification";
@import "./_modules/responsive";

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";

/* Importing Bootstrap SCSS file. */

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$p-Bandhu-Admin-Portal-primary: mat.define-palette(mat.$indigo-palette);
$p-Bandhu-Admin-Portal-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$p-Bandhu-Admin-Portal-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$p-Bandhu-Admin-Portal-theme: mat.define-light-theme(
  (
    color: (
      primary: $p-Bandhu-Admin-Portal-primary,
      accent: $p-Bandhu-Admin-Portal-accent,
      warn: $p-Bandhu-Admin-Portal-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($p-Bandhu-Admin-Portal-theme);

/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: $base-font-family !important;
  font-size: $base-font;
}
label {
  margin-bottom: 0px;
}
ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
button:focus {
  outline: none;
}

body {
  background: linear-gradient(90.97deg, #307fe2 -20.89%, #2f2f80 170.05%);
  font-weight: $medium-weight;
  font-size: $base-font;
}

.guru-container {
  padding: 35px 65px 0px 65px;
  min-height: calc(100vh - 160px);
  background: $white;
  @media screen and (max-width: $tablet-large-width) {
    padding: 25px 40px 0px 40px;
  }
}
