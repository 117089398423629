.campaign-wrapper {
  padding-top: 10px;
  .campaign-box {
    margin-bottom: 25px;
    position: relative;
    padding: 20px;
  }
  .detail-link {
    position: absolute;
    right: 20px;
    bottom: 20px;
  }
}
.campaign-item {
  display: flex;
  align-items: center;
}
.campaign-box-img {
  width: 50%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.campaign-detail-box {
  width: 50%;
  padding: 15px;
  h3 {
    margin-bottom: 8px;
    color: #307fe2;
    font-weight: bold;
  }
}
.campagin-date-box {
  width: 38%;
  height: 150px;
  display: flex;
  align-items: center;
  background: #fff;
  box-shadow: 2px 6px 15px rgba(15, 56, 85, 0.12);
  @include border-radius(8px);
  overflow: hidden;
}

.campaign-content-box {
  width: 62%;
  padding-left: 30px;
  padding-bottom: 10px;
  display: flex;
  align-items: center;
  .campaign-content {
    font-size: $base-font;
    font-weight: 400;
    color: $black;
    font-family: $base-font-family !important;
  }
}

.leadersboard-members {
  min-width: 150px;
  position: relative;
  padding-right: 45px;
  &:after {
    position: absolute;
    content: "";
    width: 1px;
    height: 100%;
    right: 25px;
    background: #d4e3f0;
    top: 0px;
  }
  h4 {
    margin-bottom: 10px;
  }
  li {
    font-weight: 700;
    margin-bottom: 3px;
  }
}

.campaign-paginator {
  @include flexStyle(flex, center, space-between);
  padding: 10px 0px;
  .page-item {
    .page-link {
      border: 0px;
      color: $primary-color;
      width: 32px;
      height: 32px;
      line-height: 32px;
      font-size: 16px;
      font-weight: 500;
      border-radius: 3px;
      margin: 0px 3px;
      padding: 0px;
      text-align: center;
      span {
        display: none;
      }
    }
    &.active {
      .page-link {
        background: #2f2f80;
        color: #fff;
      }
    }
    .page-link[aria-label] {
      margin: 0px;
    }
  }
}
