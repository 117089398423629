.bandhu-tabs {
    .mat-button-toggle-group-appearance-standard {
      border: 1px solid #c2d6e7;
      border-radius: 20px;
      .mat-button-toggle + .mat-button-toggle {
        border: 0px;
      }
      .mat-button-toggle-appearance-standard {
        color: $secondary-color;
        font-weight: 500;
        .mat-button-toggle-label-content {
          line-height: 38px;
          font-size: 18px;
          padding: 0px 36px;
          min-width: 115px;
        }
        &.mat-button-toggle-checked {
          font-weight: 700;
          background: $dark-blue-color;
          @include border-radius(20px);
          color: $white;
        }
      }
      .mat-ripple, .mat-button-toggle-focus-overlay{
          display: none;
      }
    }
  }