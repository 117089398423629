
@font-face {
    font-family: 'Uni Neue';
    src: url('../../assets/fonts/fontfabric_-_unineueblack-webfont.woff') format('woff2'),
         url('../../assets/fonts/fontfabric_-_unineueblack-webfont.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Uni Neue';
    src: url('../../assets/fonts/fontfabric_-_unineuebold-webfont.woff2') format('woff2'),
         url('../../assets/fonts/fontfabric_-_unineuebold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Uni Neue';
    src: url('../../assets/fonts/fontfabric_-_unineueregular-webfont.woff2') format('woff2'),
         url('../../assets/fonts/fontfabric_-_unineueregular-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Uni Neue';
    src: url('../../assets/fonts/fontfabric_-_unineuebook-webfont.woff2') format('woff2'),
         url('../../assets/fonts/fontfabric_-_unineuebook-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Uni Neue';
    src: url('../../assets/fonts/fontfabric_-_unineuelight-webfont.woff2') format('woff2'),
         url('../../assets/fonts/fontfabric_-_unineuelight-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
