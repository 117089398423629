.progress {
  overflow: visible;
  @include border-radius(13px);
  width: 100%;
  height: 9px;
  background-color: #d5e2ef;
  justify-content: flex-start;
  align-items: center;
}
.progress-bar {
  background: #2bb24c;
  -webkit-transition: width 1.5s ease-in-out;
  transition: width 1.5s ease-in-out;
  @include border-radius(50px);
  margin: 0 2px;
  height: 5px;
  position: relative;
}
.progress-bar::before {
  position: absolute;
  content: attr(data-value);
  width: 80px;
  height: 25px;
  background: url("../../assets/images/progress-tooltip.svg") no-repeat center
    center/cover;
  right: -60px;
  top: -29px;
  color: $primary-color;
  font-weight: bold;
  font-size: 13px;
  padding-top: 2px;
}
.progress-text {
  display: flex;
  justify-content: space-between;
  padding-top: 3px;
  font-weight: 400;
  font-size: 14px;
  color: #282828;
}
