.p-1620 {
  padding: 16px 20px;
}
.p-020 {
  padding: 0px 20px;
}
.mat-dialog-content.pt-0 {
  padding-top: 0px !important;
}
.p-016 {
  padding: 0px 16px;
}
.p-030 {
  padding: 0px 30px;
}
.pdl-16 {
  padding-left: 16px;
}
.pl-75 {
  padding-left: 75px !important;
}
.mr-20 {
  margin-right: 20px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.mlr-5 {
  margin: 0px 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mlr-70 {
  margin: 0px 70px !important;
}
.pdl-20 {
  padding-left: 20px;
}
.pdl-35 {
  padding-left: 35px;
}
.mtb-20 {
  margin: 20px 0px;
}
.w-110 {
  width: 110px;
}
.w-80 {
  width: 80px !important;
}
.w-190 {
  width: 190px !important;
}
.w-100px {
  width: 100px !important;
}
.w-70 {
  width: 70px !important;
}
.w-250 {
  width: 250px;
}
.w-236 {
  width: 236px;
}
.w-348 {
  width: 348px;
  @media screen and (max-width: 1280px) {
    width: 300px;
  }
  @media screen and (max-width: 1200px) {
    width: 210px;
  }
}
.w-560 {
  width: 560px;
}
.w-158 {
  width: 158px !important;
}
.w-160 {
  width: 160px;
}
.w-230 {
  width: 230px !important;
}
.w-268 {
  width: 268px;
}
.w-296 {
  width: 296px;
}
.w-294 {
  width: 294px !important;
}
.w-153 {
  width: 153px;
}
.w-148 {
  width: 148px !important;
}
.w-90 {
  width: 90px !important;
}
.w-130 {
  width: 130px;
}
.w-400 {
  width: 400px;
}
.hidden {
  display: none !important;
}
.top-30 {
  top: 30px;
}
.row {
  margin-right: -10px;
  margin-left: -10px;
}
.fontweight-bold {
  font-weight: 700;
  color: $primary-color;
}
.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-right: 10px;
  padding-left: 10px;
}

.border-bottom {
  border-bottom: 1px solid $border-color;
}
.border-right {
  border-right: 1px solid $border-color;
}
.border-top {
  border-top: 1px solid $border-color;
}

.semibold-text {
  font-weight: $semibold-weight;
}
.bold-text {
  font-weight: $bold-weight;
}
.fs-18 {
  font-size: $large-font;
}
.fs-12 {
  font-size: $small-font;
}
.black {
  color: $primary-color;
}
.bg-light-blue {
  background-color: $tab-bg-blue;
}
.light-grey {
  color: $lighter-grey;
}
.border-transparent {
  border: transparent !important;
}
.border-right-transparent {
  border-right: transparent !important;
}
.mt--15 {
  margin-top: -15px;
}
.mb-50 {
  margin-bottom: 50px !important;
}

.col-full {
  display: flex;
  margin: 0px -16px;
  .col-half {
    width: 50%;
    padding: 0px 16px;
  }
  .col-quarter {
    width: 25%;
    padding: 0px 16px;
  }
  .col-one-third {
    width: 33.33%;
    padding: 0px 16px;
  }
}
