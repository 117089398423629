// 0.1 FONTS
$base-font           :  14px;
$extra-large-font    :  $base-font + 10; // ~24px
$large-font          :  $base-font + 4;  // ~18px
$medium-font         :  $base-font + 2;  // ~16px
$small-font          :  $base-font - 2;  // ~12px
$extra-small-font    :  $base-font - 4;  // ~10px

// theme font family 
$base-font-family : "Uni Neue", "Helvetica Neue", sans-serif;

// 0.2 FONT WEIGHT
$light-weight        :   300;
$normal-weight       :   400;
$medium-weight       :   500; 
$semibold-weight     :   600;
$bold-weight         :   700;

// 0.4 BREAKPOINTS
$desktop-large-width        :   1600px;
$desktop-medium-width       :   1366px;
$desktop-small-width        :   1280px;
$tablet-large-width         :   1200px;
$tablet-landscape           :   1024px;



