.guru-list-table {
  border: 0.8px solid #d4e3f0;
  @include border-radius(8px);
  overflow: hidden;
}
.no-item-found {
  padding: 30px;
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  border: 0.8px solid #d4e3f0;
  @include border-radius(8px);
  margin-bottom: 30px;
  display: inline-block;
  width: 100%;
  &.no-border {
    border: 0px;
    font-size: 18px;
  }
}

.guru-tabs-row {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .guru-tabs-items {
    h2 {
      margin-bottom: 0px;
      text-transform: capitalize;
    }
  }
  .tb-btn-group {
    ul {
      display: flex;
      position: relative;
    }
    button.primary-stroke-button {
      margin-left: 12px;
      min-width: 100px;
      @include flexStyle(flex, center, center);
      &.filterBtn {
        &::before {
          content: "";
          width: 14px;
          height: 12px;
          margin-right: 8px;
          @include mask(contain, center center, no-repeat);
          -webkit-mask-image: url("../../assets/images/filter-icon.svg");
          mask-image: url("../../assets/images/filter-icon.svg");
          background: $dark-blue-color;
        }
      }
      &.exportBtn {
        &::before {
          content: "";
          width: 12px;
          height: 12px;
          margin-right: 7px;
          @include mask(contain, center center, no-repeat);
          -webkit-mask-image: url("../../assets/images/export-icon.svg");
          mask-image: url("../../assets/images/export-icon.svg");
          background: $dark-blue-color;
        }
      }
      &.open {
        background: $dark-blue-color;
        color: $white !important;
        &::before {
          background: $white;
        }
      }
    }
  }
  .guru-tabs-items ul {
    display: flex;
    list-style: none;
    padding: 0px;
    margin: 0px;
    li {
      margin-right: 28px;
      font-size: $medium-font;
      font-weight: 500;
      a {
        color: $secondary-color;
        display: flex;
        position: relative;
        align-items: center;
        cursor: pointer;
        font-weight: 500;
      }
      &.bullet-state {
        a::before {
          content: "";
          width: 6px;
          height: 6px;
          display: inline-block;
          @include border-radius(25px);
          margin-right: 10px;
        }
        &.active {
          a::before {
            background-color: $green-color;
          }
        }
        &.transacting {
          a::before {
            background-color: $orange-color;
          }
        }
        &.inactive {
          a::before {
            background-color: $red-color;
          }
        }
      }
      &.active-tab {
        a {
          color: $dark-blue-color;
          font-weight: 700;
          &:after {
            position: absolute;
            content: "";
            width: 100%;
            height: 2px;
            background: $dark-blue-color;
            left: 0px;
            bottom: -10px;
          }
        }
      }
    }
  }
}

.tb-table {
  width: 100%;
  font-family: $base-font-family;
  thead {
    background: linear-gradient(
      260.04deg,
      rgba(0, 113, 255, 0.1) 12.25%,
      rgba(154, 198, 253, 0.1) 58.71%
    ) !important;
    .tb-head-row {
      th {
        border-bottom-color: #d0d6dc;
        color: $primary-color;
        font-size: $base-font;
        font-family: $base-font-family;
        font-weight: 600;
        .mat-sort-header-content::after {
          content: "";
          background: url(../../assets/images/sort-icon.svg) no-repeat center
            center;
          transform: none !important;
          width: 19px;
          height: 19px;
        }
        .mat-sort-header-arrow {
          display: none;
        }
      }
      th[aria-sort="ascending"] {
        .mat-sort-header-content::after {
          background: url(../../assets/images/sort-asc-icon.svg) no-repeat
            center center;
        }
      }
      th[aria-sort="descending"] {
        .mat-sort-header-content::after {
          background: url(../../assets/images/sort-desc-icon.svg) no-repeat
            center center;
        }
      }
    }
  }
  .tb-item-row {
    td {
      font-size: $base-font;
      font-weight: $medium-weight;
      color: $primary-color;
      padding-top: 16px;
      padding-bottom: 16px;
      vertical-align: middle;
      text-transform: capitalize;
      word-break: break-word;
      padding-right: 15px;
      .comn-item-detail {
        flex: 1;
      }
      .guru-img-cont,
      .comn-img-cont {
        display: flex;
        align-items: center;
        .guru-image,
        .comn-item-img {
          width: 38px;
          height: 38px;
          border-radius: 50px;
          overflow: hidden;
          margin-right: 10px;
          background: #f1f1f1;
          border: 1px solid #c0d5e8;
          img {
            max-width: 100%;
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .guru-detail {
          flex: 1;
        }
      }
      &.mat-column-contact,
      &.mat-column-Contact {
        font-weight: 700;
      }
      .guru-name,
      .comn-item-name {
        color: $light-blue-color;
        font-weight: bold;
      }
      .small-font {
        display: block;
        color: $secondary-color;
        font-size: $small-font;
        line-height: 1.4;
      }
    }
    &:last-child {
      td {
        border-bottom: 0px;
      }
    }
  }

  .guru-detailed-col {
    width: 230px;
    padding-right: 10px;
  }
  .garageArea-col {
    width: 12%;
  }
  .garageName-col {
    width: 13%;
  }
  .contact-col {
    width: 135px;
    padding-right: 15px !important;
    font-weight: 600 !important;
  }
  .associate-col {
    width: 15%;
  }
  .guru-first-col,
  tbody .tb-item-row td:first-child {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 24px;
      height: 100%;
      background: transparent;
      left: 0px;
      top: 1px;
    }
  }
  .guru-last-col,
  tbody .tb-item-row td:last-child {
    position: relative;
    &:before {
      position: absolute;
      content: "";
      width: 24px;
      height: 100%;
      background: $white;
      right: 0px;
      top: 1px;
    }
  }
  .guru-last-co-leads,  tbody .tb-item-row td:last-child {
    position: relative;
    &:before {
      position: relative;
      content: "";
      width: 24px;
      height: 100%;
      background: $white;
      right: 0px;
      top: 1px;
    }
  }
}

.tb-table {
  .guru-first-col:after {
    position: absolute;
    content: "";
    left: 0px;
    z-index: 99;
    top: 50%;
    width: 5px;
    height: 38px;
    @include transform(translateY(-50%));
    @include border-radius(0px 3px 3px 0px);
  }

  .gold {
    .guru-first-col:after {
      background: linear-gradient(
        180deg,
        #ffbd00 0%,
        #fff7e0 52.08%,
        #ffbd00 100%
      );
    }
  }
  .bronze {
    .guru-first-col:after {
      background: linear-gradient(
        180deg,
        #d38240 0%,
        #f7e8dc 52.08%,
        #d38240 100%
      );
    }
  }
  .silver {
    .guru-first-col:after {
      background: linear-gradient(
        180deg,
        #c2c2c2 0%,
        #f2f2f2 50.52%,
        #c2c2c2 100%
      );
    }
  }
  .platinum {
    .guru-first-col:after {
      background: linear-gradient(
        180deg,
        #757575 0%,
        #e6e6e6 52.08%,
        #757575 100%
      );
    }
  }
}

.tb-paginator {
  position: relative;
  margin-top: 20px;
  padding-bottom: 50px;
  .mat-paginator-page-size {
    display: none;
  }
  .mat-paginator-range-label {
    position: absolute;
    left: 0px;
    font-size: $base-font;
    color: #777;
    font-weight: 500;
  }
}

.guru-list-title {
  @include flexStyle(flex, center, space-between);
  margin-bottom: 20px !important;
}
.tb-table-wrapper {
  padding-top: 50px;
}

/*===filter style===*/

.filterCloseBtn {
  background: url(../../assets/images/close-icon.svg) no-repeat right center;
  border: 0px;
  outline: none;
  width: 16px;
  cursor: pointer;
  height: 17px;
}
.custom-filter-sec {
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  width: 500px;
  right: 0px;
  padding: 20px 30px;
  z-index: 99;
  top: 60px;

  h3 {
    font-weight: 700 !important;
    display: flex;
    justify-content: space-between;
  }
  .custom-filter-body {
    display: flex;
    flex-wrap: wrap;
    max-height: 550px;
    overflow-y: auto;
    .custom-filter-col {
      width: 41%;
      margin-bottom: 20px;
      &.filter-small-col {
        width: 24%;
      }
    }

    h4.form-label {
      font-weight: 700;
      margin-bottom: 5px;
    }
    .form-chkbox {
      .mat-checkbox-label {
        font-size: $base-font;
        white-space: break-spaces;
        line-height: inherit;
      }
    }
  }
  .filterBtn-group {
    margin: 0px -7.5px;
    padding-top: 10px;
    button {
      width: 50%;
      margin: 0px 7.5px !important;
    }
  }

  &.two-col-filter {
    width: 325px;
    .custom-filter-col {
      width: 50%;
      padding-right: 15px;
      &.filter-small-col {
        width: 50%;
        padding-right: 15px;
      }
    }
  }
  &.one-col-filter {
    width: 230px;
    padding: 20px 25px;
    .custom-filter-col {
      width: 100%;
      padding-right: 0px;
      &.filter-small-col {
        width: 100%;
        padding-right: 0px;
      }
    }
  }
}

// custom paginator styles start

.custom-paginator {
  padding: 25px 0px;
  position: relative;
  .mat-icon-button {
    width: 32px;
    height: 32px;
    span {
      display: none;
    }
  }

  .custom-paginator-counter {
    color: #777;
    font-weight: bold;
    position: absolute;
    left: 0px;
  }
  .mat-paginator-navigation-previous,
  .page-link[aria-label="Previous"] {
    background: url(../../assets/images/pagination-previous.svg) no-repeat
      center center;
  }
  .mat-paginator-navigation-first,
  .page-link[aria-label="First"] {
    background: url(../../assets/images/pagination-firsy.svg) no-repeat center
      center;
  }
  .mat-paginator-navigation-next,
  .page-link[aria-label="Next"] {
    background: url(../../assets/images/pagination-next.svg) no-repeat center
      center;
  }
  .mat-paginator-navigation-last,
  .page-link[aria-label="Last"] {
    background: url(../../assets/images/pagination-last.svg) no-repeat center
      center;
  }
  .mat-icon-button.custom-paginator-arrow-disabled,
  .page-item.disabled {
    opacity: 0.4;
  }
  .custom-paginator-page-disabled {
    opacity: 1;
  }

  .custom-paginator-page {
    font-size: 14px;
    width: 32px;
    height: 32px;
    max-height: inherit;
    font-weight: 500;
    color: $primary-color;
    margin: 0px 2px;
    padding: 0px;
    &.custom-paginator-page-disabled {
      font-weight: 700;
      color: $white;
    }
  }
}

::ng-deep.custom-paginator-container {
  justify-content: flex-end;
}

.custom-paginator-counter {
  white-space: nowrap;
  margin: 0px 4px 0px 10px !important;
}

.custom-paginator-page {
  border-radius: 4px;
  outline: none;
  border: none;
  margin: 0.4em;
  font-size: 14px;
  min-width: 24px;
  width: auto;
  min-height: 24px;
  max-height: 24px;
  padding: 1px 6px;
}

.custom-paginator-page-enabled {
  color: #333333;
  background: transparent;
  cursor: pointer;
  &:hover {
    background-color: #dfe3e6;
  }
}

.custom-paginator-page-disabled {
  background-color: $dark-blue-color;
  color: #fff;
}

.custom-paginator-arrow-disabled {
  color: #dfe3e6;
}

.custom-paginator-arrow-enabled {
  color: #999999;
}

::ng-deep .mat-paginator-outer-container {
  display: none !important;
}

::ng-deep .mat-paginator-container {
  display: none !important;
}

.custom-paginator-container {
  button.mat-button-disabled {
    background-color: transparent !important;
  }
}
