.profile-container {
  padding: 20px 26px;
}
.data-container {
  width: 100%;
  padding: 21px 40px 0px 15px;
  .data-tab-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 40px;
  }
}

.coupon-box-wrap {
  @include flexStyle(flex, center, unset);
  margin: 0px -7.5px;
  padding-bottom: 22px;

  .profile-box {
    background: rgba(255, 255, 255, 0.4);
    border: 1px solid $lightest-blue-color;
    box-sizing: border-box;
    @include border-radius(6px);
    min-height: 84px;
    margin: 0px 7.5px;
    width: 20%;
    padding: 10px 10px 10px 20px;
    &.active {
      border-bottom: 2px solid $green-color;
    }
    &.transacting {
      border-bottom: 2px solid $orange-color;
    }
    &.inactive {
      border-bottom: 2px solid $red-color;
    }
    h2 {
      font-size: 25px;
      font-weight: $normal-weight;
      color: $primary-color;
      text-transform: unset;
      margin-bottom: 0px;
      small {
        font-size: $base-font;
        color: $secondary-color;
        display: block;
      }
    }
  }
}
.user-information-box {
  display: flex;
  background: rgba(255, 255, 255, 0.4);
  border: 1px solid $lightest-blue-color;
  box-sizing: border-box;
  @include border-radius(6px);
  min-height: 69px;
  margin: 0px 7.5px;
  width: 100%;
  padding: 15px 0px 10px 20px;
  & > div {
    min-width: 120px;
  }
  h4 {
    font-weight: $normal-weight !important;
    color: $primary-color !important;
    text-transform: unset !important;
    margin-bottom: 0px !important;

    small {
      font-size: $base-font;
      color: $primary-color;
      font-weight: $medium-weight;
      display: block;
    }
  }
  .seprator {
    background-color: $lightest-blue-color;
    display: inline-block;
    width: 1px;
    height: 42px;
    vertical-align: middle;
    margin: 0 34px;
  }
}

.main-profile-container {
  display: flex;
  justify-content: space-between;
}

.tb-phone {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 27px;
  .phone-icon {
    width: 14px;
    height: 14px;
    background: url(../../assets/images/phone.svg) no-repeat center center /
      cover;
    margin-right: 5px;
  }
  a {
    color: $primary-color;
    font-weight: 500;
    text-decoration: none;
    &:hover {
      color: $link-color;
    }
  }
}
