::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $lightest-grey;
  opacity: 1; /* Firefox */
  font-weight: $normal-weight;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $lightest-grey;
  font-weight: $normal-weight;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $lightest-grey;
  font-weight: $normal-weight;
}

input,
button,
select,
textarea,
.mat-typography,
.mat-radio-button,
.mat-checkbox {
  font-family: $base-font-family !important;
}

.form-chkbox {
  margin-top: 5px;
  .mat-checkbox-ripple {
    display: none;
  }
  .mat-checkbox-frame {
    border-width: 1px;
  }
  .mat-checkbox-label {
    font-size: $medium-font;
    font-weight: $medium-weight;
    color: $primary-color;
  }
}
.form-check {
  margin-bottom: 0px;
}

.form-radio {
  .mat-radio-button {
    display: block;
    margin-bottom: 10px;
    .mat-radio-container {
      width: 18px;
      height: 18px;
      .mat-radio-outer-circle {
        width: 18px;
        height: 18px;
        border: 1px solid #a8a8a8;
      }
      .mat-radio-inner-circle {
        width: 10px;
        height: 10px;
        left: 50%;
        top: 50%;
        background-color: $white;
        @include transform(translate(-50%, -50%) scale(0.5));
      }
    }
    &.mat-radio-checked {
      .mat-radio-outer-circle {
        background: $dark-blue-color;
        border: $dark-blue-color;
      }
    }
    .mat-radio-ripple {
      display: none;
    }
    .mat-radio-label-content {
      font-size: $medium-font;
      color: $primary-color;
      font-weight: $medium-weight;
    }
  }
}

.form-label {
  font-weight: $medium-weight;
  font-size: $base-font;
  color: $primary-color;
  display: block;
  margin-bottom: 8px;
  text-transform: capitalize;
  font-family: $base-font-family;
}
.form-control {
  background: $white;
  border: 1px solid $lightest-blue-color;
  @include border-radius(5px);
  height: 40px;
  line-height: 1.4;
  padding: 5px 10px;
  width: 100%;
  font-family: $base-font-family;
  font-weight: $medium-weight;
  font-size: $medium-font;
  color: $primary-color;
  &:focus {
    border: 1px solid $dark-blue-color;
    outline: none;
  }
}
select {
  &.form-control {
    font-size: 14px;
    height: 40px !important;
    appearance: none;
    background: $white url(../../assets/images/btm-arrow.svg) no-repeat right
      10px center / 10px;
  }
}

span.requiredfield {
  color: $red-color;
}
.invalid-field {
  position: absolute;
  font-size: $small-font;
  color: $red-color;
  bottom: -25px;
  font-weight: $medium-weight;
}
.invalid-input {
  border-color: $red-color;
}
.error-message {
  color: $red-color;
  font-size: $small-font;
  font-weight: $medium-weight;
}
.formfield-hint {
  font-size: $small-font;
  font-weight: $medium-weight;
  color: $secondary-color;
  margin-top: 5px;
}
.form-date {
  width: 100%;
  &.mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: 0px;
      border-top: 0px;
    }
    .mat-form-field-wrapper {
      margin: 0px;
      padding: 0px;
    }
    .mat-form-field-flex {
      margin-top: 0px;
    }
    .mat-form-field-outline {
      height: 40px;
      background: $white;
      border: 1px solid $lightest-blue-color;
      @include border-radius(5px);
    }
    .mat-form-field-outline-thick {
      top: 0px;
    }
    .mat-form-field-outline-end,
    .mat-form-field-outline-start {
      display: none;
    }
    .mat-form-field-outline {
      top: 0px;
    }

    input.mat-input-element {
      line-height: 26px;
      margin-top: 0px;
      font-size: $medium-font;
      font-weight: $medium-weight;
    }
    &.mat-focused {
      .mat-form-field-outline {
        border: 1px solid $dark-blue-color;
      }
    }
    .mat-button-ripple.mat-ripple,
    .mat-icon-button .mat-button-focus-overlay {
      display: none;
    }
  }
  .mat-datepicker-toggle .mat-button-wrapper {
    background: url(../../assets/images/calendar.svg);
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: center center;
    svg {
      display: none !important;
    }
  }
  .mat-date-range-input {
    position: relative;
    top: -5px;
  }
  .mat-date-range-input-inner {
    font-weight: 500;
    font-size: 14px;
  }
  &.disabled {
    pointer-events: none;
    .mat-form-field-outline {
      background-color: #eceeef;
    }
  }
}
.form-control:disabled,
.form-control[readonly] {
  pointer-events: none;
}
.form-file {
  width: 100%;
  &.mat-form-field-appearance-outline {
    .mat-form-field-infix {
      padding: 0px;
      border-top: 0px;
      display: flex;
      height: 40px;
    }
    .mat-form-field-wrapper {
      margin: 0px;
      padding: 0px;
      border: 1px solid $lightest-blue-color;
      @include border-radius(5px);
      background: $white;
    }
    .mat-form-field-flex {
      margin-top: 0px;
      padding-right: 0px;
    }
    .mat-form-field-outline-thick {
      top: 0px;
    }
    .mat-form-field-outline-end,
    .mat-form-field-outline-start {
      display: none;
    }
    .mat-form-field-outline {
      top: 0px;
    }
    input.mat-input-element {
      margin-top: 0px;
      font-size: $medium-font;
      font-weight: $medium-weight;
    }
    &.mat-focused {
      .mat-form-field-wrapper {
        border: 1px solid $dark-blue-color;
      }
    }
    .mat-button-ripple.mat-ripple,
    .mat-icon-button .mat-button-focus-overlay {
      display: none;
    }
  }
  .uploadBtn {
    background: $light-blue-color;
    color: $white;
    min-width: auto;
    @include border-radius(5px 5px);
    font-weight: $bold-weight;
    min-width: 150px;
  }
}

.form-select {
  width: 100%;
  &.mat-form-field-appearance-outline {
    .mat-form-field-infix {
      border-top: 0px;
    }
    .mat-form-field-wrapper {
      margin: 0px;
      padding: 0px;
    }
    .mat-form-field-flex {
      margin-top: 0px;
    }
    .mat-form-field-outline {
      height: 40px;
      background: $white;
      border: 1px solid $lightest-blue-color;
      @include border-radius(5px);
    }
    .mat-form-field-outline-thick {
      top: 0px;
    }
    .mat-form-field-outline-end,
    .mat-form-field-outline-start {
      display: none;
    }
    .mat-form-field-outline {
      top: 0px;
    }

    input.mat-input-element {
      line-height: 26px;
      margin-top: 0px;
      font-size: $medium-font;
      font-weight: $medium-weight;
    }
    &.mat-focused {
      .mat-form-field-outline {
        border: 1px solid $dark-blue-color;
      }
    }
    .mat-button-ripple.mat-ripple,
    .mat-icon-button .mat-button-focus-overlay {
      display: none;
    }
  }
  .mat-select-placeholder {
    color: $primary-color;
  }
  .mat-select.mat-select-invalid .mat-select-arrow {
    color: $primary-color !important;
  }
}

.tb-select-dropdown {
  position: relative;
  .tb-selectedVal {
    border: 1px solid #2f2f80;
    background: $white;
    @include border-radius(4px);
    height: 40px;
    line-height: 38px;
    padding: 0px 15px;
    font-size: $base-font;
    font-weight: 700;
    color: #2f2f80;
    width: 140px;
    background: url(../../assets/images/dropdown-icon.svg) no-repeat right 15px
      center / 10px 6px;
    outline: none;
    cursor: pointer;
    text-align: left;
  }
  .tb-dropdown-options {
    background: $white;
    box-shadow: 0px 5px 40px rgb(0 0 0 / 12%);
    border-radius: 6px;
    margin-top: 10px;
    position: absolute !important;
    top: 40px;
    width: 100%;
    z-index: 999;
    padding: 13px 0px 10px 0px;
    display: block !important;

    li {
      padding: 7px 20px;
      color: $black;
      font-size: $base-font;
      font-weight: 500;
      &.selected,
      &:hover {
        background: #eff6ff;
      }
    }
  }
}
.uploadReport-container {
  border: 2px dashed #d4e3f0;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  color: #282828;
  display: inline-block;
  padding-bottom: 40px;
  .or-wrap {
    margin: 20px 0px;
  }
  .upload-img-item,
  .upload-file-item {
    img {
      margin-bottom: 30px;
      max-width: 100%;
      max-height: 150px;
    }
  }
  .file-input__input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  .file-input__label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    font-weight: 600;
    color: #fff;
    font-size: 14px;
    padding: 10px 22px;
    background: #2f2f80;
  }
}
.mat-autocomplete-panel {
  .mat-option {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
  }
}
