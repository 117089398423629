.guru-nav-icon {
  height: 30px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;

  &.dash-icon {
    background-image: url(../../assets/images/dashboard.svg);
    background-size: 28px 27px;
  }
  &.user-icon{
    background-image: url(../../assets/images/user.svg);
    background-size: 30px 44px;
  }
  &.guru-list-icon {
    background-image: url(../../assets/images/gurulist.svg);
    background-size: 37px 28px;
  }
  &.msr-list-icon {
    background-image: url(../../assets/images/msrlist-icon.svg);
    background-size: 37px 28px;
  }
  &.distributor-list-icon {
    background-image: url(../../assets/images/store_black_24dp.svg);
    background-size: 37px 28px;
  }

  &.apm-list-icon {
    background-image: url(../../assets/images/apmlist.svg);
    background-size: 37px 28px;
  }
  &.reports-icon {
    background-image: url(../../assets/images/reports.svg);
    background-size: 29px 29px;
  }
  &.upload-icon {
    background-image: url(../../assets/images/uplod-doc.png);
    background-size: 29px 29px;
  }
  &.loyalty-program-icon {
    background-image: url(../../assets/images/loyalty-icon.svg);
    background-size: 30px 30px;
  }
  &.campaign-icon {
    background-image: url(../../assets/images/campaign.svg);
    background-size: 33px 26px;
  }
  &.training-icon {
    background-image: url(../../assets/images/training-icon.svg);
    background-size: 31px 27px;
  }
  &.targets-icon {
    background-image: url(../../assets/images/targets.svg);
    background-size: 29px 29px;
  }
}
.sub-menu-icon {
  background-image: url(../../assets/images/btm-arrow.svg);
  width: 10px;
  height: 6px;
  background-size: 10px 6px;
  margin-left: 5px;
  margin-top: 5px;
}
.tb-nav-menu {
  li.active {
    .guru-nav-icon {
      &.dash-icon {
        background-image: url(../../assets/images/dashboard-active-icon.svg);
      }
      &.guru-list-icon {
        background-image: url(../../assets/images/gurulist-active-icon.svg);
      }
      &.reports-icon {
        background-image: url(../../assets/images/reports-active-icon.svg);
      }
      &.upload-icon{
        background-image: url(../../assets/images/uplod-doc.png);
      }
      &.loyalty-program-icon {
        background-image: url(../../assets/images/loyalty-active-icon.svg);
      }
      &.campaign-icon {
        background-image: url(../../assets/images/campaign-active-icon.svg);
      }
      &.training-icon {
        background-image: url(../../assets/images/trainning-active-icon.svg);
      }
      &.msr-list-icon {
        background-image: url(../../assets/images/msrlist-icon-active.svg);
      }
      &.targets-icon {
        background-image: url(../../assets/images/target-active-icon.svg);
      }
      &.distributor-list-icon {
        background-image: url(../../assets/images/store_black_active.svg);
        background-size: 37px 28px;
      }
    }
  }
}
