// 0.1 BORDER RADUIS
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
  }

  @mixin transition($transition) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -ms-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
  }
  //  GENERIC TRANSFORM
  @mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
  }

  // SCALE
  @mixin scale($scale) {
    @include transform(scale($scale));
  }
  // TRANSLATE
  @mixin translate ($x, $y) {
    @include transform(translate($x, $y));
  }
  
  // ROTATE
  @mixin rotate ($deg) {
    @include transform(rotate(#{$deg}deg));
  }
  
  // FLEXBOX DISPLAY
  @mixin flex() {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
  
  // CROSS-AXIS ALIGNMENT
  @mixin align-items($align) {
    -webkit-align-items: $align;
    -moz-align-items: $align;
    -ms-align-items: $align;
    align-items: $align;
  }

// Packing Flex Lines
// - applies to: multi-line flex containers
// flex-start | flex-end | center | space-between | space-around | stretch 
  @mixin align-content($align) {
    -webkit-align-content: $align;
    -moz-align-content: $align;
    -ms-align-content: $align;
    align-content: $align;
  }

// Axis Alignment
// - applies to: flex containers
// flex-start | flex-end | center | space-between | space-around 
@mixin justify-content($justify) {
  -webkit-justify-content: $justify;
     -moz-justify-content: $justify;
      -ms-justify-content: $justify;
          justify-content: $justify;
            -ms-flex-pack: $justify;
  }
  
  // CALC HEIGHT AND WIDTH BAAED ON VIEWPORT
  @mixin calc($property, $expression) {
    #{$property}: -moz-calc(#{$expression});
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
  }
  
  // BOX SHADOW
  @mixin box-shadow($shadow) {
    -webkit-box-shadow: $shadow;
    box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -o-box-shadow: $shadow;
    border-collapse: separate;
  }
  
  // BOX SHADOW NONE
  @mixin box-shadow-none{
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    -ms-box-shadow: none;
    box-shadow: none;
  }
  
  // TEXT OVERFLOW
  @mixin text-overflow{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  // RESPONSIVE BREAKPOINTS
  
  // ABOVE 1366PX
  @mixin desktop-large{
    @media screen and (max-width: $desktop-large-width) {
      @content;
    }
  }
  // ABOVE 1440PX
  @mixin desktop-medium{
    @media screen and (max-width: $desktop-medium-width) {
      @content;
    }
  }
  // ABOVE 1280PX
  @mixin desktop-small{
    @media screen and (max-width: $desktop-small-width) {
      @content;
    }
  }
  // TABLET LANDSCAPE 1024PX
  @mixin tab-landscape{
    @media screen and (max-width: $tablet-landscape) {
      @content;
    }
  }
  @mixin mask($maskSize, $maskPosition, $maskRepeat) {
    mask-size: $maskSize;
    -webkit-mask-size: $maskSize;
    mask-position: $maskPosition;
    -webkit-mask-position: $maskPosition;
    mask-repeat: $maskRepeat;
    -webkit-mask-repeat: $maskRepeat;
  }
  
    @mixin flexStyle($dispaly, $alignItem, $justCont) {
    display: $dispaly;
    -webkit-align-items: $alignItem;
    align-items: $alignItem;
    -webkit-justify-content: $justCont;
    justify-content: $justCont;
  }
  
@mixin textTruncate {
  max-width:100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}