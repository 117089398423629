.tb-searchbox{
    display: flex;
    position: relative;
    width: 390px;
    height: 38px;
    background-color:$white;
    @include border-radius(5px);
    @media screen and (max-width: $tablet-large-width){
        width: 100%;
    } 
}
.tb-header{
    .tb-searchbox{
        @media screen and (max-width: $tablet-large-width){
            width: 375px;
        } 
    }
}
.tb-search-text {
    padding: 5px 5px 5px 15px;
    outline: none;
    padding-right: 40px;
    width: 100%;
    border:none;
    font-size: $base-font;
    font-weight: 500;
    border: 1px solid #C2D6E7;
    @include border-radius(5px);
    &:focus{
        border-color: $dark-blue-color;
    }
}
.tb-button{
    border: none;
    background:$dark-blue-color;
    @include border-radius(3px);
    width:28px;
    height:28px;
    position: absolute;
    right: 5px;
    top: calc(50% - 28px/2);
    
}
.tb__search_icon{
    background: url(../../assets/images/search.svg) no-repeat;
    width: 20px;
    height: 20px;
    margin: 3px 0px 0px 1px;
}