.videosList {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -16px;
    padding-bottom: 15px;
    li {
      width: 25%;
      padding: 0px 16px;
      margin-bottom: 35px;
      .video-item {
        background: $white;
        box-shadow: 2px 6px 15px rgba(15, 56, 85, 0.12);
        @include border-radius(6px);
        padding: 6px;
        height: 100%;
        .video-img {
          img {
            max-width: 100%;
            @include border-radius(6px);
          }
        }
        .video-content {
          background: #ecf4fe;
          border-radius: 5px;
          padding: 10px 10px 15px 10px;
          margin-top: 6px;
          position: relative;
          min-height: 90px;
          h4 {
            font-weight: 700;
            margin-bottom: 8px;
          }
          p {
            margin-bottom: 0px;
            color: #7b7b7b;
            font-weight: 500;
            font-size: 12px;
          }
        }
      }
      .share-video {
        background: url(../../assets/images/share-icon.svg) no-repeat center
          center / cover;
        width: 13px;
        height: 15px;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}
.video-img {
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background: #282828;
    opacity: 0.5;
    position: absolute;
    @include border-radius(6px);
    left: 0px;
    top: 0px;
  }
}
.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  @include transform(translate(-50%, -50%));
  @include transition(all 0.5s);
  background: url(../../assets/images/VideoPlay-icon.svg) no-repeat center
    center / cover;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.highlight-video {
  display: flex;
  margin: 36px 0px;
  h1 {
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 10px;
  }
  .highlight-video-img {
    width: 50%;
    position: relative;
    img {
      max-width: 100%;
    }
    margin-right: 25px;
  }
  .highlight-video-content {
    width: 50%;
    position: relative;
    .highlight-content {
      max-width: 400px;
    }
    .highlight-video-btm {
      position: absolute;
      bottom: 5px;
      left: 0px;
      font-weight: 500;
    }
    .highlight-video-top {
      padding-bottom: 20px;
      padding-right: 120px;
      max-height: 245px;
      overflow-y: auto;
      p {
        font-weight: 500;
        line-height: 1.4;
        margin-bottom: 15px;
      }
      .hashtags {
        color: #7b7b7b;
        font-weight: 500;
      }
      .more-content {
        margin-top: 15px;
      }
    }
  }
  .share-video-btn {
    min-width: 103px;
    font-weight: 700;
    position: absolute;
    right: 0px;
    top: 0px;
    i.share-icon {
      background: url(../../assets/images/highlight-share-icon.svg) no-repeat
        center center / 12px 8px;
      width: 12px;
      height: 8px;
      margin-right: 5px;
      display: inline-block;
    }
  }
}

.training-video-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  h2 {
    margin-bottom: 0px;
  }
  .searchdrop-column {
    display: flex;
  }
  .tb-searchbox {
    margin-right: 13px;
  }
}
.videosList {
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0px -16px;
    padding-bottom: 15px;
    li {
      width: 25%;
      padding: 0px 16px;
      margin-bottom: 35px;
      .video-item {
        cursor: pointer;
        background: $white;
        box-shadow: 2px 6px 15px rgba(15, 56, 85, 0.12);
        @include border-radius(6px);
        padding: 6px;
        height: 100%;
        display: block;
        .video-img {
          img {
            max-width: 100%;
            @include border-radius(6px);
            height: 165px;
            object-fit: cover;
          }
        }
        .video-content {
          background: #ecf4fe;
          border-radius: 5px;
          padding: 10px 10px 15px 10px;
          margin-top: 6px;
          position: relative;
          h4 {
            font-weight: 700;
            margin-bottom: 8px;
            padding-right: 10px;
          }
          p {
            margin-bottom: 0px;
            color: #7b7b7b;
            font-weight: 500;
            font-size: 12px;
          }
        }
      }
      .share-video {
        background: url(../../assets/images/share-icon.svg) no-repeat center
          center / cover;
        width: 13px;
        height: 15px;
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
  }
}
.video-img {
  position: relative;
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background: #282828;
    opacity: 0.5;
    position: absolute;
    @include border-radius(6px);
    left: 0px;
    top: 0px;
  }
}
.play-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  @include transform(translate(-50%, -50%));
  @include transition(all 0.5s);
  background: url(../../assets/images/VideoPlay-icon.svg) no-repeat center
    center / cover;
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.highlight-video {
  display: flex;
  margin: 36px 0px;
  h1 {
    font-weight: 500;
    padding-top: 5px;
    margin-bottom: 10px;
  }
  .highlight-video-img {
    width: 50%;
    position: relative;
    background: #eee;
    video {
      outline: none;
      max-height: 300px;
    }
    img {
      max-width: 100%;
    }
    margin-right: 25px;
  }
  .highlight-video-content {
    width: 50%;
    position: relative;
    .highlight-content {
      max-width: 400px;
    }
    .highlight-video-btm {
      position: absolute;
      bottom: 5px;
      left: 0px;
      font-weight: 500;
    }
    .highlight-video-top {
      padding-bottom: 20px;
      padding-right: 120px;
      max-height: 245px;
      overflow-y: auto;
      p {
        font-weight: 500;
        line-height: 1.4;
        margin-bottom: 15px;
      }
      .hashtags {
        color: #7b7b7b;
        font-weight: 500;
      }
      .more-content {
        margin-top: 15px;
      }
    }
  }
  .share-video-btn {
    min-width: 103px;
    font-weight: 700;
    position: absolute;
    right: 0px;
    top: 0px;
    i.share-icon {
      background: url(../../assets/images/highlight-share-icon.svg) no-repeat
        center center / 12px 8px;
      width: 12px;
      height: 8px;
      margin-right: 5px;
      display: inline-block;
    }
  }
}

.training-video-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;

  h2 {
    margin-bottom: 0px !important;
  }
  .searchdrop-column {
    display: flex;
  }
  .tb-searchbox {
    margin-right: 13px;
  }
}
