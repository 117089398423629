

$white:#fff;
$black:#000;
$primary-color:#282828; 
$secondary-color:#7B7B7B; 
$dark-blue-color:#2F2F80; 
$light-blue-color:#307FE2;  
$green-color:#2bb24c;
$light-green-color:#ECFFD6;
$red-color: #D83232;
$light-red-color: #FFE9E9;
$orange-color: #FC9D45;
$yellow-color: #FFBD00;
$dark-grey-color:#757575;
$light-grey-color:#A8A8A8;
$brown-color: #D38240;
$lightest-blue-color:#d4e3f0;
$background-shade: linear-gradient(250.04deg, rgba(0, 113, 255, 0.1) 12.25%, rgba(154, 198, 253, 0.1) 58.71%);
$lightest2-blue-color:#ECF4FE;



$grey-black-text:#4c4c4c;
$light-grey-bg:#f3f5f8;
$grey-highlight:#dee2e9;
$light-grey-back: #f8f8f8;
$border-color:#dddfef;
$grey-hint:#c5c5c5;
$disabled-grey: #ababab;
$bright-grey: #56595e;
$icon-low-bg:#46c5f2;
$icon-medium-bg:#ffc741;
$icon-high-bg:#d2162d;
$brown: #B34D00;
$lightbrown: #FFF5EF;
$green:#337b09;
$lightgreen:#edffe7;
$blue:#0d2db6;
$lightblue: #DDE4FF;
$lightestblue: #ebf2f8;
$floating-yellow: #754E00;
$floating-bg: #FFF9E9;
$floating-border: #FFE6B9;
$icon-star:#ffac00;
$off-white: #E4E3E3;
$light-grey: #4B4B4B;
$divider-grey: #c5c5c5;
$tab-bg-blue:#f2f8ff;
$dark-cyan: #007faa;
$lighter-grey: #4a4a4a;
$lightest-grey: #a8a8a8;
$frequent-grey: #979797;
$shadow-grey: #A7B8D0;
$grey-subtitle: #9b9b9b;
$grey-bg:#f1f3f6;
$warning-color:#d0021b;
$pink-color:#e03e8c;
$purple-color:#648fff;
$dark-purple:#71679e;
$brown-color: #a57e3b;
$bronze-color:#eeddd8;
$silver-color:#dde3ee;
$gold-color:#f7dfc3;
$diamond-color:#e6dbf3;
$hover-shadow: #d7dce5;
$lightgrey: #bfc9d5;
$grey-light: #d8d8d8;
$light-orange-color: #dd7a00;
$dark-green: #00af27;
$light-green: #f1fdf4;
$light-orange: #fefaf6;
$dark-read: #a22424;
$light-pink: #fff2f2;
$grey-badge-text: #777;
$grey-progress-bar: #d5e2ef;
