@import "../../sass/_modules/colors";
@import "../../sass/_modules/mixins";
@import "../../sass/_modules/variables";

.login-wrapper {
  background: url(../../assets/images/Dotsbackground.png) repeat center center /
    cover;
  height: 100%;
}
.login-header {
  padding: 30px 40px;
  @include flexStyle(flex, center, space-between);
}
.partner-logos {
  ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    li {
      padding: 0px 20px;
      position: relative;
      &:before {
        position: absolute;
        content: " ";
        width: 1px;
        height: 38px;
        background: #d4e3f0;
        opacity: 0.3;
        left: 0px;
      }
      &:first-child {
        &::before {
          content: none;
        }
      }
    }
  }
}
.loginPanelFormSec {
  h2.tollfreeNum {
    text-transform: capitalize;
    color: $white;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 15px;
  }
}
body .loginPanel {
  h1 {
    font-size: 38px;
    color: $white;
    font-weight: 400;
    margin-bottom: 50px;
  }
}
.loginPanel {
  @include flexStyle(flex, center, space-between);
  padding: 0px 108px;
  height: calc(100% - 104px);
  h1 {
    b {
      font-weight: 700;
    }
    span {
      display: block;
      text-align: center;
    }
  }
  .gift-img {
    margin-left: -25px;
  }
}
.loginPanelLeft {
  margin-top: -30px;
}

.loginPanelForm {
  background: #fff $background-shade;
  border: 0.8px solid $lightest-blue-color;
  box-sizing: border-box;
  @include border-radius(8px);
  padding: 35px 45px 35px;
  width: 480px;
  min-height: 350px;
  h2 {
    text-transform: capitalize;
    text-align: center;
  }
  p {
    text-align: center;
    font-size: 13px;
    font-weight: 500;
  }
  .form-row {
    margin-top: 35px;
  }
  .form-col {
    margin-top: 20px;
  }
  .form-forgot-link {
    margin-top: 15px;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    a {
      color: $light-blue-color;
      font-weight: 500;
      font-size: $small-font;
    }
  }
  .form-btn {
    margin-top: 35px;
    .formSubmitbtn {
      width: 100%;
      background: $dark-blue-color;
      border: 1px solid $dark-blue-color;
      font-size: $base-font;
      font-weight: bold;
      &.mat-button-disabled {
        background: #eaeaea;
        color: #fff;
        border: 0px;
      }
    }
  }
  &.forgotPassPanel {
    min-height: 350px;
  }
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #9e9e9e;
  opacity: 1;
  font-size: $base-font;
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #9e9e9e;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #9e9e9e;
}
@media screen and (max-width: $desktop-small-width) {
  .loginPanel {
    .gift-img {
      max-width: 540px;
      padding-right: 30px;
      img {
        max-width: 100%;
      }
    }
  }
  .loginPanelForm {
    min-height: inherit;
  }
  .loginPanelFormSec {
    h2.tollfreeNum {
      margin-top: 30px;
    }
  }
}
@media screen and (max-width: $tablet-large-width) {
  body .loginPanel {
    padding: 0px 60px;
    h1 {
      font-size: 34px;
      margin-bottom: 30px;
    }
  }
  .login-header {
    padding: 30px;
  }
  .loginPanelForm {
    max-width: 450px;

    h2 {
      margin-bottom: 15px;
    }
    .form-btn {
      margin-top: 30px;
    }
  }
  .loginPanelFormSec {
    h2.tollfreeNum {
      margin-top: 20px;
    }
  }
}

.custom-login-error {
  margin-top: 35px;
  color: #d83232;
  border-color: #d83232;
  background-color: #f2dede;
  font-weight: 500;
  padding: 10px 15px;
  @include border-radius(5px);
}

.custom-success-message {
  font-weight: 500;
  padding: 10px 15px;
  @include border-radius(5px);
  background-color: #dff0d8;
  border-color: #d0e9c6;
  color: #3c763d;
  margin-top: 30px;
  a {
    font-weight: 600;
    color: #3c763d;
    text-decoration: underline;
  }
}
.custom-warning-msg {
  background-color: #cce5ff;
  border-color: #cce5ff;
  color: #004085;
  .primary-button {
    color: #ffffff !important;
    margin-right: 10px;
    display: inline-block;
    border-radius: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    background: #004085;
    padding: 2px 10px;
  }
}
