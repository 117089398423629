.add-item-content.form-wrap {
  padding: 15px 15px;
  margin-top: 50px;
  .form-row {
    display: flex;
    .form-col {
      width: 33.33%;
      padding: 0px 15px;
      position: relative;
      margin: 15px 0px;
    }
  }

  .addUser-btn {
    padding: 15px 15px 15px 15px;
    button {
      min-width: 120px;
      padding-left: 30px;
      padding-right: 30px;
      border-color: #2f2f80;
      font-weight: 500;
      border-radius: 5px;
      cursor: pointer;
    }
  }
}
.superadmin-tabs {
  select.form-control {
    text-transform: capitalize;
  }
  .table-wrapper {
    margin-top: 30px;
  }
  .mat-column-user_id {
    width: 20%;
  }
  .name-col {
    width: 25%;
  }
}
