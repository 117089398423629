.rating {
  @include flexStyle(flex, center, center);
  i.star {
    width: 16px;
    height: 16px;
  }
  img {
    margin-right: 2px;
  }
}

.rating i.star,
.rating.five-star i {
  background: url(../../assets/images/fill-start.svg) no-repeat center center /
    cover;
  margin-right: 2px;
}

.rating.zero-star i {
  background: url(../../assets/images/empty-star.svg) no-repeat center center /
    cover;
}
.rating.one-star i {
  background: url(../../assets/images/empty-star.svg) no-repeat center center /
    cover;
}

.rating.one-star i:first-child {
  background: url(../../assets/images/fill-start.svg) no-repeat center center /
    cover;
}

.rating.two-star i.star:nth-child(3),
.rating.two-star i.star:nth-child(4),
.rating.two-star i.star:nth-child(5) {
  background: url(../../assets/images/empty-star.svg) no-repeat center center /
    cover;
}

.rating.three-star i.star:nth-child(4),
.rating.three-star i.star:nth-child(5) {
  background: url(../../assets/images/empty-star.svg) no-repeat center center /
    cover;
}

.rating.four-star i.star:last-child {
  background: url(../../assets/images/empty-star.svg) no-repeat center center /
    cover;
}

.guru-profile-image {
  width: 116px;
  height: 116px;
  border-radius: 50%;
  display: inline-block;
  overflow: hidden;
  background: #ffc107;
  padding: 2px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    border-color: #fff;
    border: 3px solid #fff;
  }
}
.profile-display-container {
  text-align: center;
  width: 261px;
  padding: 10px 15px 15px 15px;
  background: #ffffff;
  box-shadow: 2px 6px 15px rgba(15, 56, 85, 0.12);
  @include border-radius(8px);
  height: 100%;
  .profile-text-wrap {
    padding-top: 15px;
  }
  h3 {
    margin-bottom: 10px !important;
    text-transform: capitalize;
  }
}
